export const ERROR_TYPE = Object.freeze({
    notFound        : '404',
    wip             : 'WIP',
    technicalProblem: 'TECHNICAL_PROBLEM',
});

export const ERROR_ACTION = Object.freeze({
    redirect: 'REDIRECT',
    reload  : 'RELOAD',
});
